// @flow
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* eslint-disable-next-line import/order */ // Prioritize loading polyfills.
import '@ungap/global-this'

import { type GatsbyBrowser, navigate } from 'gatsby'
import { nanoid } from 'nanoid'
import * as React from 'react'

import { callback, session } from '@toggl/auth'

import { pricingPlanUrls } from './data/track/signup'
import { Provider, abTester } from './src/components/Provider'

type $GatsbyBrowser<API: string> = $ElementType<GatsbyBrowser, API>

export const wrapRootElement: $GatsbyBrowser<'wrapRootElement'> = ({
  element,
}) => <Provider>{element}</Provider>

const getExperimentId = (): string => {
  const id = localStorage.getItem('experiment_id') || nanoid()
  localStorage.setItem('experiment_id', id)

  return id
}

export const onClientEntry: $GatsbyBrowser<'onClientEntry'> = () => {
  if (window?.heap?.identify && session.check()) {
    window.heap.identify(session.getUserId() ?? '')
  }

  abTester.setAttributes({
    id: getExperimentId(),
  })

  callback.redirectLegacyAuthCallbacks()
}

export const onInitialClientRender: $GatsbyBrowser<'onInitialClientRender'> = () => {
  callback.handleAuthErrors(navigate, pricingPlanUrls)
}
